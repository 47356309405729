import React, { useState, useEffect, useRef } from 'react';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { Helmet } from "react-helmet";
import EasyBoy from './EasyBoy';
import MyRulesBoard1 from './MyRulesBoard1'
import Donation1 from './mobile/Donation1'
import KDBasicboard from './dashboard/KDBasicboard'
import NewBasicboard from './dashboard/NewBaiscboard'
import Badge from '@material-ui/core/Badge';
import ProDashboard from './dashboard/ProDashboard'
import MoneyDashboard from './dashboard/MoneyDashboard';
import MyStrageBoard from './strage/MyStrageBoard';
import SaveRuleDialog from './dialog/SaveRuleDialog'
import StockUser from './StockUser'
import AppMenu2 from './mobile/AppMenu2';
import AppMenu from './mobile/AppMenu';

import MainDetail3 from './details/MainDetail3';
import BColor from './BColor'
import BackTest from './backtest/BackTest'
import BPage from './BPage'
import KTest from './ktest/KTest'
import ReportDashboard from './report/ReportDashboard1'
import ShapeHome from './shape/ShapeHome1';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PriceShapeHome from './shape/PriceShapeHome'
import IndustryHome from './industry/IndustryHome1'
import WinerDashboard from './winner/WinerDashboard1';
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BigDetails from './bigtables/BigDetails'
import { putInpQueryParam } from './util/Syno'
import ShapeDashboard from './dashboard/ShapeDashboard';
import TableToExcel from "@linways/table-to-excel";
import ShapeDashboard1 from './dashboard/ShapeDashboard1'

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Box, Button } from '@material-ui/core';
import AppMenu3 from './mobile/AppMenu3';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Adsense } from '@ctrl/react-adsense';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: "#bccfe8",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem)

const useStyles = makeStyles((theme) => ({
    // const styles = theme => ({
    root: {
        minHeight: '100vh',
        height: "100vh",

    },
    rule: {
        border: '1px solid #ccc',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 5,
            marginBottom: 0,
            marginRight: 5,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 30,
            marginBottom: 0,
            marginRight: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 30,
            marginBottom: 0,
            marginRight: 30,
        },
    },
    table: {
        left: 0,
        right: 0,
        position: "fix",
        bottom: 0,
        marginBottom: 0,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    tableLabel: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 5,
            marginRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 5,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 5,
            marginRight: 0,
        },
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        paddingTop: 10,
        color: theme.palette.type === "light" ? theme.palette.secondary.dark : "#FFFFFF"
    },
    tabcontainer: {
        paddingTop: 10,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8 * 1,
            paddingRight: 8 * 1,
            paddingBottom: 5,
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 8 * 1,
            paddingRight: 8 * 1,
            paddingBottom: 0,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 0,
        },
    },
    tabRoot: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.default,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
        // backgroundColor:"#000000"

    },
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    padding: {
        padding: theme.spacing(0, 2),

    },
    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor
    },

}))

function makeid1(length) {
    var result = '';
    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default function Dashboard3(props) {
    const theme = useTheme();
    const classes = useStyles();
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const [error, setError] = React.useState(null)
    const [isLoaded, setIsLoaded] = React.useState(true)
    const [items, setItems] = React.useState(null)
    const [rules, setRules] = React.useState([])
    const [tabIndex, setTabIndex] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [page, setPage] = React.useState(0)
    const [count, setCount] = React.useState(20)
    const [total, setTotal] = React.useState(0)
    const [bigtitle, setBigTitle] = React.useState(null)
    const [usercount, setUserCount] = React.useState(0)
    const [open1, setOpen1] = React.useState(false)
    const [open2, setOpen2] = React.useState(false)
    const [updatemylove, setUpdateMyLove] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [subtabIndex, setSubTabIndex] = React.useState(0)
    const [refreshr, setRefreshR] = React.useState(localStorage.getItem("refreshr") === 'true')
    const [lockScroll, setLockScroll] = React.useState(false)

    const myRef = React.useRef()
    const needRefresh = React.useRef()
    needRefresh.current = true

    const autoRules = React.useRef([])
    const autoPage = React.useRef(0)
    const autoCount = React.useRef(20)


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null)
    };

    const handleCloseMenu1 = (i, s) => {
        setAnchorEl(null)
        setTabIndex(i)
        setSubTabIndex(s)
    };


    const handleClickOpen1 = () => {
        setOpen1(true)
    };

    const handleClose1 = () => {
        setOpen1(false)
    };

    const handleClose2 = () => {
        setOpen2(false)
    };

    const getSynNo = () => {
        let id = makeid1(7) + "000"
        localStorage.setItem("synno", id)
        return id
    }

    const handleSaveRule1 = () => {
        setOpen1(true)
    };


    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }

    // useEffect(() => {
    //     try {
    //         (window.adsbygoogle = window.adsbygoogle || []).push({});
    //     }

    //     catch (e) {
    //     }
    // }, []);

    const fectchAPI = (param) => {
        fetch("/jstock/v1/stock/rules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setIsLoaded(true)
                        setItems(result.company)
                        setTotal(result.total)
                        setBigTitle(result.bigtitle)
                        setError(null)
                    } else {
                        setOpen(true)
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => {
                    setIsLoaded(true)
                }
            );
    }

    const handleSearchClose = () => {
        if (tabIndex === BPage.love_page) {
            setUpdateMyLove(!updatemylove)
        }
    }

    const checkLic = () => {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let a = {
                lic: lic,
                inp: {}
            }

            fetch("/jstock/v1/stock/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null && (result.hlic.licok === 1 || result.hlic.tp === 3)) {
                                localStorage.setItem('noad', 1);
                                // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("1");
                            } else {
                                localStorage.setItem('noad', 0);
                                // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                            }
                        } else {
                            localStorage.setItem('noad', 0);
                            // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                        }
                    },
                    error => {
                        // localStorage.setItem('noad', 0);
                        // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                    }
                );
        } else localStorage.setItem('noad', 0);
    }

    const runFectchAPII = (rules, pages, count) => {
        if (rules.length === 0) {
            setIsLoaded(true)
            setItems(null)
            setError(null)
            setRules([])
            autoRules.current = []
        } else {
            let a = {
                rules: rules,
                page: pages,
                count: count,
                inp: {}
            };

            if (localStorage.getItem('lic') != null)
                a["lic"] = localStorage.getItem('lic')
            if (localStorage.getItem('token') != null)
                a["token"] = localStorage.getItem('token')

            a = putInpQueryParam(a)
            fectchAPI(a);
        }
    }

    const runRuleData = (rules) => {
        runFectchAPII(rules, page, count)
        setRules(rules)
        autoRules.current = rules
    };

    const clearRuleData = () => {
        setIsLoaded(true)
        setItems(null)
        setError(null)
        setRules([])
        autoRules.current = []
    };

    const updateRuleData = (id, data, enabled) => {
        if (enabled) {
            onAddItem(id, data);
        } else {
            onRemoveItem(id);
        }
    };

    useEffect(() => {
        checkLic()

        let h = moment().hour()
        let w = moment().day()

        if (localStorage.getItem("refreshr") === 'true' && h >= 9 && h < 14 && w > 0 && w < 6) {
            const interval = setInterval(() => {
                let h1 = moment().hour()
                let w1 = moment().day()

                if (h1 >= 9 && h1 < 14 && w1 > 0 && w1 < 6 && localStorage.getItem("refreshr") === 'true') {
                    if (needRefresh.current) runAutoFectchAPI()
                }
            }, 25000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [refreshr]);

    const runAutoFectchAPI = () => {
        runFectchAPII(autoRules.current,
            autoPage.current,
            autoCount.current)
    }

    const runFectchAPII1 = () => {
        runFectchAPII(rules, page, count)
    }

    const bigupdate = () => {
        runFectchAPII1()
    }

    const freecashupdate = () => {
        runFectchAPII1()
    }

    const pageupdate = (page, count) => {
        runFectchAPII(rules, page, count)
        setPage(page)
        setCount(count)
        autoPage.current = page
        autoCount.current = count
    }

    const onAddItem = (id, data) => {
        const rules1 = rules.filter(item => item.id !== id)
        const isArray = Array.isArray(data);
        let rules2 = rules1
        if (isArray) {
            let dataIds = data.map(i => i.id)
            rules2 = rules1.filter(item => !dataIds.includes(item.id))
        }

        const finalRules = rules2.concat(data)

        runFectchAPII(finalRules, 0, count)
        setPage(0)
        setRules(finalRules)
        autoRules.current = finalRules
        autoPage.current = 0
    };

    const onRemoveItem = id => {
        const rules1 = rules.filter(item => item.id !== id);

        if (rules1.length === 0) {
            setIsLoaded(true)
            setItems(null)
            setError(null)
            setRules([])
            autoRules.current = []
        } else {
            runFectchAPII(rules1, 0, count)
            setRules(rules1)
            autoRules.current = rules1
            setPage(0)
            autoPage.current = 0
        }
    };


    const handleTabChange = (event, value) => {
        if (value === 3) {
        } else {
            setTabIndex(value)
            setSubTabIndex(0)
        }
    };

    const handleTabChange1 = (value) => {
        setTabIndex(value)
    }

    const handleOpen = () => {
        let URL = "https://p.ecpay.com.tw/5C4E5#price"
        var win = window.open(URL, "_blank");
    }

    const renderKeyNode = () => {
        if (tabIndex === BPage.donate_page || tabIndex == BPage.love_page || tabIndex === BPage.backtest_page) {
            return ""
        } else if (error) {
            return "";
        } else if (!isLoaded) {
            return "";
        } else if (items === null) {
            return "";
        } else if (items.length === 0) {
            return "";
        } else if (lockScroll) {
            return "";
        } else {
            return "免責聲明: 本站提供之分析資料、選股工具僅供參考，不暗示買賣建議，本站對資訊正確、更新延誤或傳輸中斷不負任何責任，依本站資訊交易發生損失需自行負責，請謹慎評估風險。"
        }
    }

    const detailUpdate = (value) => {
        needRefresh.current = !value
    }

    const renderTable = () => {
        if (tabIndex === BPage.donate_page || tabIndex === BPage.love_page || tabIndex === BPage.backtest_page || tabIndex === BPage.ktest_page) {
            return ""
        } else if (error) {
            return "";
        } else if (!isLoaded) {
            return "";
        } else if (items === null) {
            return "";
        } else if (items.length === 0) {
            return "";
        } else if (lockScroll) {
            return "";
        } else {
            if (isComputer())
                return <MainDetail3 detailUpdate={detailUpdate} data={items} dense={true} rules={rules} total={total} pageupdate={pageupdate} currentpage={page} currentcount={count} disablepage={false} bigtitle={bigtitle} freecashupdate={freecashupdate} bigupdate={bigupdate} ismobile={false} />;
            else return <MainDetail3 detailUpdate={detailUpdate} data={items} dense={true} rules={rules} total={total} pageupdate={pageupdate} currentpage={page} currentcount={count} disablepage={false} bigtitle={bigtitle} freecashupdate={freecashupdate} bigupdate={bigupdate} ismobile={true} />;
        }
    }

    const lockScreen = (data) => {
        setLockScroll(data)
    }

    const handleCChange = (event) => {
        localStorage.setItem("refreshr", event.target.checked)
        setRefreshR(event.target.checked)

    };

    const renderTitle = () => {
        if (tabIndex === BPage.donate_page || tabIndex === BPage.love_page || tabIndex === BPage.backtest_page || tabIndex === BPage.ktest_page) {
            return ""
        } else if (error) {
            return <div>
                {"系統正在維修，請稍後再嘗試"}
            </div>;
        } else if (!isLoaded) {
            return "載入中";
        } else if ((items === null || rules.length === 0) && tabIndex !== BPage.love_page) {
            return ""
        } else if (items.length === 0) {
            return <div>
                {"挖掘不到任何股票。條件太嚴苛或者是太多了嗎？"}
            </div>;
        } else if (lockScroll) {
            return "";
        } else {
            return <div style={{}}>
                <FormControlLabel
                    control={<Checkbox color="primary" checked={refreshr} onChange={handleCChange} name="checkedA" />}
                    label={total + "筆，自動挖股"}
                />
            </div>
        }
    }

    const getToph = () => {
        if (props.location.pathname === "/rules") {
            return 0
        } else return 0
    }

    const isComputer = () => {
        return props.location.pathname === "/rules"
    }

    const isMobile = () => {
        return props.location.pathname === "/mdashboardios" || props.location.pathname === "/mdashboard" || props.location.pathname === "/mdashboard1" || props.location.pathname === "/mdashboardg"
    }

    const exporta = () => {
        TableToExcel.convert(document.getElementById("table1"));
    }
    // overflowY: lockScroll ? "hidden" : "auto",

    const openGame = () => {
        window.open("https://game.stock-digger.com/#open", "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
    }

    return (
        <div className={classes.root} style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000", }}>
            <Helmet>
                <script src="assets/jquery-3.3.1.slim.min.js"></script>
                <script src="assets/popper.min.js"></script>
                <script src="assets/bootstrap.min.js"></script>
                <script src="assets/smooth-scroll/smooth-scroll.js"></script>
                <script src="assets/dropdown/js/script.min.js"></script>
            </Helmet>

            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="generator" content="Mobirise v4.10.8, mobirise.com" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1"
            />
            <link
                rel="shortcut icon"
                href="assets/images/logo-transparent-1-128x128.png"
                type="image/x-icon"
            />
            <meta name="description" content="股票挖土機" />
            <title>股票挖土機</title>

            <link rel="stylesheet" href="assets/tether/tether.min.css" />
            <link rel="stylesheet" href="assets/bootstrap/css/bootstrap.min.css" />
            <link rel="stylesheet" href="assets/shopping-cart/minicart-theme.css" />
            <link rel="stylesheet" href="assets/dropdown/css/style.css" />
            <link rel="stylesheet" href="assets/theme/css/style.css" />
            <link rel="stylesheet" href="assets/formoid/recaptcha.css" />
            <link
                rel="stylesheet"
                href="assets/mobirise/css/mbr-additional.css"
                type="text/css"
            />


            <AppBar position="static" style={{ backgroundColor: '#000000', }}>
                <Toolbar>
                    <Link to="/" style={{ marginRight: 10 }}>
                        <Avatar src="assets/images/logo-transparent-1-128x128.png" />
                    </Link>
                    {matches && <Typography style={{ whiteSpace: "nowrap", fontSize: 18, fontWeight: "bold", flexGrow: 1, marginRight: 10 }}>
                        股票挖土機
                    </Typography>}
                    <Box style={{ width: matches ? "40%" : "100%" }}>
                        <AppMenu3 closeupdate={handleSearchClose} ismobile={isMobile()} />
                    </Box>
                </Toolbar>
            </AppBar>



            <section className="form cid-rAAn8dGBSy" id="formbuilder-v">
                <div className={classes.tabRoot}>
                    <SaveRuleDialog rules={rules} open={open1} handleClose={handleClose1}></SaveRuleDialog>
                    <AppBar position="static" color="default" >
                        <Tabs
                            value={tabIndex}
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={handleTabChange}>

                            <Tab label="輕鬆選股" />
                            <Tab label="股民私藏" />
                            <Tab label="大盤統計" />
                            <Tab onClick={handleClickMenu} label="進階選股" />
                            <Tab label="方案價格" />
                            <Tab label="自選股" />
                            <Tab label="選股策略" />
                            <Tab label="交易日誌" />
                            <Tab label="回測系統" />
                            <Tab label="相似型態" />

                        </Tabs>
                        <StyledMenu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 0)}>基本面</StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 1)}>技術面</StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 2)}>籌碼面</StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 7)}>
                                <Badge style={{ paddingTop: 4 }} badgeContent={"PRO"}><font style={{}}>券商選股</font>
                                </Badge>
                            </StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 3)}>
                                <Badge style={{ paddingTop: 4 }} badgeContent={"PRO"}><font style={{}}>產業選股</font>
                                </Badge>
                            </StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 4)}>
                                <Badge style={{ paddingTop: 4 }} badgeContent={"PRO"}><font style={{}}>懶人選股</font>
                                </Badge>
                            </StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 8)}>
                                <Badge style={{ paddingTop: 4 }} badgeContent={"PRO"}><font style={{}}>型態選股1</font></Badge>
                            </StyledMenuItem>
                            <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 9)}>
                                <Badge style={{ paddingTop: 4 }} badgeContent={"PRO"}><font style={{}}>型態選股2</font></Badge>
                            </StyledMenuItem>
                            {/* <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 5)}>
                                    <Badge style={{  paddingTop: 4 }} badgeContent={"PRO"}><font style={{  }}>轉折波型態</font></Badge></StyledMenuItem>
                                <StyledMenuItem onClick={(e) => handleCloseMenu1(3, 6)}>
                                    <Badge style={{  paddingTop: 4 }} badgeContent={"PRO"}><font style={{  }}>量價型態</font></Badge></StyledMenuItem> */}
                        </StyledMenu>
                    </AppBar>

                    {getNoAd() === "0" && <Adsense
                        client="ca-pub-8613274553211553"
                        slot="5934285082"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />}

                    {/* {getNoAd() === "0" && <img src="promotion1.png" onClick={e => openGame()} />} */}

                    {tabIndex === 0 && <div className={classes.tabcontainer} style={{}} >
                        <EasyBoy saveRule1={handleSaveRule1} export={exporta} updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} tabchange={handleTabChange1} pathname={props.location.pathname} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 1 && <div className={classes.tabcontainer} style={{ paddingTop: 15 }} >
                        <StockUser updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 2 && <div style={{ paddingTop: 0 }} >
                        <BigDetails ismobile={isMobile()} />
                    </div>}

                    {tabIndex === 3 && subtabIndex === 0 && <div className={classes.tabcontainer}>
                        <NewBasicboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 1 && <div className={classes.tabcontainer}>
                        <KDBasicboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} freecashupdate={freecashupdate} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 2 && <div className={classes.tabcontainer}>
                        <MoneyDashboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 3 && <div className={classes.tabcontainer}>
                        <IndustryHome updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 4 && <div className={classes.tabcontainer}>
                        <ProDashboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} freecashupdate={freecashupdate} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 5 && <div className={classes.tabcontainer}>
                        <ShapeHome updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 6 && <div className={classes.tabcontainer}>
                        <PriceShapeHome updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 7 && <div className={classes.tabcontainer}>
                        <WinerDashboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 8 && <div className={classes.tabcontainer}>
                        <ShapeDashboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === 3 && subtabIndex === 9 && <div className={classes.tabcontainer}>
                        <ShapeDashboard1 updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} onRemoveItem={onRemoveItem}
                            lockScroll={lockScroll} lockScreen={lockScreen} />
                    </div>}

                    {tabIndex === BPage.donate_page && <div className={classes.tabcontainer} style={{ paddingTop: 10 }} >
                        <Donation1 ismobile={isMobile()} />
                    </div>}

                    {tabIndex === BPage.love_page && <div className={classes.tabcontainer} style={{ paddingTop: 10 }} >
                        <MyRulesBoard1 runRuleData={runRuleData} tabchange={handleTabChange1} clearRule={clearRuleData} updatemylove={updatemylove} mobile={isMobile()} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === BPage.myrule_page && <div className={classes.tabcontainer} style={{ paddingTop: 10 }} >
                        <MyStrageBoard runRuleData={runRuleData} tabchange={handleTabChange1} clearRule={clearRuleData} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === BPage.report_page && <div className={classes.tabcontainer}>
                        <ReportDashboard updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === BPage.backtest_page && <div className={classes.tabcontainer}>
                        <BackTest updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}
                    {tabIndex === BPage.ktest_page && <div className={classes.tabcontainer}>
                        <KTest updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} saveRule1={handleSaveRule1} tabchange={handleTabChange1} ismobile={isMobile()} />
                    </div>}

                </div>

                <div className={classes.table} ref={myRef}>
                    <div className={classes.tableLabel}>{renderTitle()}</div>
                    <div>{renderTable()}</div>
                    <div style={{ fontSize: 6, padding: 5, color: BColor.faircolor }}>
                        {renderKeyNode()}
                    </div>
                </div>
            </section>
        </div>
    );
}
